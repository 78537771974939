.navbar {
  background: transparent;
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: background-color 0.3s;
}

.navbar-active {
  background-color: #000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  padding: 0 20px;
  max-width: 1500px;
  margin: 0 auto;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo-image {
  max-width: 100%;
  height: auto;
  max-height: 80px; 
  align-items: center;
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-item {
  height: 80px;
  position: relative; 
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  transition: color 0.2s;
}

.nav-links:hover {
  color:#e59124;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  top: 80px;
}

.nav-item:hover .dropdown-content, .nav-item.active .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, 5%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    background-color: #333;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover, .dropdown-content a:hover {
    background-color: #555;
    color: #fff;
  }

  .dropdown-content {
    /* position: static; */
    width: 100%;
    box-shadow: none;
    text-align: center;
  }
}
