.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  margin-bottom: 10vh;
} 


section {
  height: 20vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.service-pages{
  margin-bottom: 500px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.card {
  width: 100%;
  height: 50vh;
  text-align: center;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #e59124 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}



.card h4{
  color: black;
}

.icon-wrapper {
  background-color: #e59124;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.card:hover {
  background-position: 0 100%;
}
.card:hover .icon-wrapper {
  background-color: #ffffff;
  color: #e59124;
}
.card:hover h3 {
  color: #ffffff;
}
.card:hover h4 {
  color: #ffffff;
}
.card:hover p {
  color: #f0f0f0;
}




.serviceicon{
  width: auto;
  height: auto;
}


.service-text{
  text-align: center;
  color: #fff;
}

.service-section {
  margin-top: 10vh;
  margin-bottom: 100vh; 
}


@media screen and (min-width: 768px) {
  section {
    padding: 0 2em;
  }
  .column {
    flex: 0 50%;
    max-width: 50%;
  }

}
@media screen and (min-width: 992px) {
  section {
    padding: 1em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}



.footer {
  margin-top: 220vh; /* Default for desktop */
}

@media (max-width: 768px) { /* Adjust 768px as needed for your mobile breakpoint */
  .footer {
    margin-top: 310vh;
  }
}
