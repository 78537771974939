/* 

.blog-post {
    margin: auto;
    max-width: 800px;
    text-align: center;
    padding-top: 100px; 
  }
  
  img {
    width: 100%; 
    height: 80%;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  p {
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 10vh;
  }
  
  h1 {
    color: #e59124; 
  }
  
  .question {
    margin-top: 30px;
   color:white;
  }
  
  
  @media (max-width: 768px) {
    .blog-post {
      padding-top: 80px; 
    }
  }
  
  
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
    }
  }
  
   */


   /* Reset margin and padding for all elements to prevent unexpected spacing */
/* *  {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* This ensures padding doesn't affect the total width of elements */
  /* } */ 
  
  /* Use a flex layout for the main structure of the page to keep header and footer at the ends */
  /* body, html {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  } */
  
  /* Ensure the content of the blog post is flexible and takes up the available space */
  /* main {
    flex: 1;
    display: flex;
    flex-direction: column;
  } */
  
  /* Styling for the blog post */
  .blog-post {
    margin: auto;
    max-width: 800px;
    text-align: center;
    padding: 100px 20px 0; /* Add horizontal padding for smaller screens */
  }
  
  /* Responsive image styling */
  img {
    width: 100%;
    height: auto; /* Set to auto to maintain aspect ratio */
    border-radius: 10px;
    margin-top: 20px;
  }
  
  /* Styling for the text content */
  p {
    line-height: 1.5;
    text-align: justify;
    padding: 0 20px; /* Add horizontal padding */
    margin-bottom: 10vh;
  }
  
  /* Styling for the question section */
  .question {
    margin-top: 30px;
    color: white;
    padding: 0 20px; /* Add horizontal padding */
  }
  
  /* Heading styling */
  h1 {
    color: #e59124;
    padding: 0 20px; /* Add horizontal padding */
  }
  
  /* Media query adjustments for tablet devices */
  @media (max-width: 768px) {
    .blog-post {
      padding: 80px 20px 0; /* Slightly reduce the top padding */
    }
  }
  
  /* Media query adjustments for mobile devices */
  @media (max-width: 600px) {
    .blog-post {
      padding: 60px 15px 0; /* Further reduce the top padding and slightly the side padding */
    }
  
    h1 {
      font-size: 1.5rem; /* Reduce the font size for h1 on smaller screens */
    }
  
    p {
      font-size: 0.9rem; /* Reduce the font size for paragraphs on smaller screens */
      margin-bottom: 5vh; /* Reduce the bottom margin */
    }
  }
  
  