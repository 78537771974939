 /* .logoMarqueeSection {
  margin-top: 5vh;
  margin-bottom: 10vh;
  }
  
.titletext{
    text-align: center;
    color: white;
    margin-top: 10vh;
    font-family: "Poppins", sans-serif;	
}

  #logoMarqueeSection {
    max-width: 1920px!important;
    margin: 0 auto;
  }
  
  .default-content-container {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
      width: 100%;
      min-height: 100vh;
  }
  
  
  
  .logoMarqueeSection>div>div {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 0;
  }
  
  .marquee-wrapper {
    display:  inline-block;
    white-space: nowrap;
  }
  
  .marquee {
      display:  inline-block;
      white-space: nowrap;
      position: relative;
      transform: translate3d(0%, 0, 0);
      animation-name: marquee;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
  }
  
  .marquee a {
      display:  inline-block;
      width: 387px;
      margin: 0 0.5em;
      padding: 0.5em 1.5em;
      height: 110px;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      background-color: #ddd;
      border-radius: 16px;
      overflow: hidden;
  }
  .marquee a mg {
    height: 50px;
  }
  
  @keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-50%, 0, 0);
    }
} 




 */



 .marquee {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.titletext{
  text-align: center;
  color: white;
  margin-top: 5vh;
  font-size: 3rem;
}


/* .marquee h3 {
  display: inline-block;
  margin-right: 50px;
  vertical-align: middle;
  margin-bottom: 5vh;
  color: white;
  text-align: center;
} */

.marquee-content {
  display: inline-flex;
  animation: scrollLeft 30s linear infinite;
}

.marquee-content img {
  width: 100px; /* Adjust based on your logo size */
  margin-right: 30px; /* Space between logos */
  vertical-align: middle;
}

/* Keyframes for the scrolling animation */
@keyframes scrollLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

