.counter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.counter {
  background-color: #222;
  color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  flex: 1; 
  min-width: 250px; 
  max-width: calc(33% - 20px); 
}
.counter:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.icon img {
  width: 100%;
  max-width: 80px; 
  height: auto; 
  margin-bottom: 10px; 
}


.number {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
  font-size: 1.5rem;
}


@media (max-width: 768px) {
  .counter-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
}
