
  
  .responsive-container-block {
    font-family: sans-serif;
    min-height: 75px;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 15vh;
    margin-left: auto;
    justify-content: flex-start;
  }
  
  a {
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 25px;
  }
  
  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 50px 10px 50px;
  }
  
  .mainImg {
    color: black;
    width: 100%;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .text-blk.headingText {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: #e59124;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  
  .allText {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    width: 40%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .text-blk.subHeadingText {
    color: white;
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .text-blk.description {
    line-height: 26px;
    text-align: justify;
    color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .explore {
    font-size: 16px;
    line-height: 28px;
    color: rgb(102, 102, 102);
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(102, 102, 102);
    border-right-color: rgb(102, 102, 102);
    border-bottom-color: rgb(102, 102, 102);
    border-left-color: rgb(102, 102, 102);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    cursor: pointer;
    background-color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 8px;
    padding-left: 40px;
  }
  
  .explore:hover {
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: #e59124;
    color: white;
    border-top-width: initial;
    border-right-width: initial;
    border-bottom-width: initial;
    border-left-width: initial;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
  }
  
  .responsive-container-block.Container {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    max-width: 1320px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  
  .responsive-container-block.Container.bottomContainer {
    flex-direction: row-reverse;
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    position: static;
  }
  
  .allText.aboveText {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 40px;
  }
  
  .allText.bottomText {
    margin-top: 0px;
    margin-right: 40px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .purpleBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 430px;
    background-color: #e59124;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    bottom: -35px;
    left: -8%;
  }
  
  .purpleText {
    font-size: 18px;
    line-height: 26px;
    color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .ultimateImg {
    width: 50%;
    position: relative;
  }
  
  @media (max-width: 1024px) {
    .responsive-container-block.Container {
      max-width: 850px;
    }
  
    .mainImg {
      width: 55%;
      height: auto;
    }
  
    .allText {
      width: 40%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .responsive-container-block.Container {
      max-width: 830px;
    }
  
    .allText.aboveText {
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 40px;
    }
  
    .allText.bottomText {
      margin-top: 30px;
      margin-right: 40px;
      margin-bottom: 0px;
      margin-left: 0px;
      text-align: left;
    }
  
    .text-blk.headingText {
      text-align: center;
    }
  
    .allText.aboveText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .text-blk.subHeadingText {
      text-align: left;
      font-size: 26px;
      line-height: 32px;
    }
  
    .text-blk.description {
      text-align: left;
      line-height: 24px;
    }
  
    .explore {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 30px;
      padding-bottom: 10px;
      padding-left: 30px;
    }
  
    .responsive-container-block.Container {
      justify-content: space-evenly;
    }
  
    .purpleBox {
      bottom: 10%;
    }
  
    .responsive-container-block.Container.bottomContainer {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      max-width: 930px;
    }
  
    .allText.bottomText {
      width: 40%;
    }
  
    .purpleBox {
      bottom: auto;
      left: -10%;
      top: 70%;
    }
  
    .mainImg {
      width: 100%;
    }
  
    .text-blk.headingText {
      text-align: left;
    }
  }
  
  @media (max-width: 768px) {
    .allText {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .responsive-container-block.Container {
      flex-direction: column;
      height: auto;
    }
  
    .text-blk.headingText {
      text-align: center;
    }
  
    .text-blk.subHeadingText {
      text-align: center;
      font-size: 24px;
    }
  
    .text-blk.description {
      text-align: center;
      font-size: 18px;
    }
  
    .allText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .allText.aboveText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin-top: 50px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .allText.bottomText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .mainImg {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: -70px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      flex-direction: column;
    }
  
    .ultimateImg {
      width: 100%;
    }
  
    .purpleBox {
      position: static;
    }
  
    .allText.bottomText {
      width: 100%;
      align-items: flex-start;
    }
  
    .text-blk.headingText {
      text-align: left;
    }
  
    .text-blk.subHeadingText {
      text-align: left;
    }
  
    .text-blk.description {
      text-align: left;
    }
  
    .ultimateImg {
      position: static;
    }
  
    .mainImg {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .ultimateImg {
      position: relative;
    }
  
    .purpleBox {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      position: absolute;
      left: 0px;
      top: 80%;
    }
  
    .allText.bottomText {
      margin-top: 100px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  }
  
  @media (max-width: 500px) {
    .responsive-container-block.Container {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      width: 100%;
      max-width: 100%;
    }
  
    .mainImg {
      width: 100%;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 25px;
      padding-bottom: 10px;
      padding-left: 25px;
    }
  
    .text-blk.subHeadingText {
      font-size: 24px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      line-height: 28px;
    }
  
    .text-blk.description {
      font-size: 16px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      line-height: 22px;
    }
  
    .allText {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      width: 100%;
    }
  
    .allText.bottomText {
      margin-top: 50px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      padding: 0 0 0 0;
      margin: 30px 0 0 0;
    }
  
    .ultimateImg {
      position: static;
    }
  
    .purpleBox {
      position: static;
    }
  
    .stars {
      width: 55%;
    }
  
    .allText.bottomText {
      margin-top: 75px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  
    .purpleText {
      font-size: 16px;
      line-height: 22px;
    }
  
    .explore {
      padding: 6px 35px 6px 35px;
      font-size: 15px;
    }
  }



  
  .box-container {
    height: 100%;
    max-width: 1000px;
    margin: 0 auto 10vh;
    text-align: center;
    padding: 30px;
    font-family: sans-serif;
   
  }
  

  .section-lead {
    max-width: 600px;
    margin: 1rem auto 1.5rem;
  }
  
  .service a {
    color: #5b7abb;
    display: block;
  }
  
  .service h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #56ceba;
    font-size: 1.3rem;
    margin: 1rem 0 0.6rem;
  }
  
  .services-grid {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
  
  .service {
    background: #222;
    margin: 20px;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #e7e7e7;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .service:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
  }
  
  .service i {
    font-size: 3.45rem;
    margin: 1rem 0;
  }
  
  .service1 i,
  .service1 h4,
  .service1 .cta {
    color: #42b7ca;
  }
  
  .service1:hover {
    border: 2px solid #42b7ca;
  }
  
  .service2 i,
  .service2 h4,
  .service2 .cta {
    color: #425fca;
  }
  
  .service2:hover {
    border: 2px solid #425fca;
  }
  
  .service3 i,
  .service3 h4,
  .service3 .cta {
    color: #9c42ca;
  }
  
  .service3:hover {
    border: 2px solid #9c42ca;
  }
  
  .service .cta span {
    font-size: 0.6rem;
  }
  
  .service > * {
    flex: 1 1 100%;
  }
  
  .service .cta {
    align-self: flex-end;
  }
  
  @media all and (max-width:900px) {
    .services-grid {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
  }