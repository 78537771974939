
.pricing {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem;
  }
  
  .card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    background-color: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0 5px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
    padding: 2rem;
    margin: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .card-wrapper:hover {
    transform: scale(1.05);
  }
  
  .card-header img {
    width: 100px; 
    margin-bottom: 1rem;
  }
  
  .card-header h2 {
    color: #333;
    text-transform: uppercase;
  }
  
  .card-detail p {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }
  
  .fa-check {
    color: #4caf50;
    margin-right: 0.5rem;
  }
  
  .fa-times {
    color: #f44336;
    margin-right: 0.5rem;
  }
  
  .card-price p {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .card-button {
    padding: 0.5rem 2rem;
    background-color: #e59124;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .card-button:hover {
    background-color: #3a1d9f;
  }
  