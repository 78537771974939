/* Footer.css */

.container {
    display: flex;
    color: white;
    background-color: #222;
  }
  
  .left, .center, .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .logo {
    width: 30%;
  }
  
  .desc {
    margin: 20px 0px;
    color: #a7a7a7;
  }
  
  .social-container {
    display: flex;
  }
  
  .social-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: transform 0.3s ease, filter 0.3s ease;
    filter: grayscale(100%);
  }
  
  .social-icon:hover {
    filter: grayscale(0);
    transform: scale(1.1);
  }
  
  .social-icon a {
    color: inherit; 
  }
  
  .title {
    margin-bottom: 30px;
  }
  
  .list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  
  .list-item {
    width: 50%;
    margin-bottom: 10px;
  }
  
  .list-item a {
    color: #a7a7a7;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .list-item:hover a {
    color: white;
  }
  
  .contact-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    color: #a7a7a7;
  }

  .copyright{
    text-align: center;
    background-color: #222;
  }

  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .logo {
      width: 60%; /* Larger on smaller screens */
    }
  
    .list-item {
      width: 100%; /* Full width on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .social-icon {
      width: 35px; 
      height: 35px;
    }
  }
  

  .whatsapp-fixed-icon {
    position: fixed;
    right: 5px;
    bottom: 10px;
    z-index: 999999;
  }
  