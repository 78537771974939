/* #blog{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 100vh;
    margin-bottom: 100vh;
  }
  .blog-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .blog-heading span{
    color: #f33c3c;
  }
  .blog-heading h3{
    font-size: 2.4rem;
    color: #e59124;
    font-weight: 600;
  }
  .blog-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    flex-wrap: wrap;
  }
  
  .blog-box{
  border-radius: 5px;
    width: calc(33.33% - 40px);
    background-color: #FFFFFF;
    border: 1px solid #ececec;
    margin: 20px 20px;
  
  }
  .blog-img{
    width:100%;
    height: auto;
  }
  .blog-img img{
    width:100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .blog-text{
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .blog-text span{
    color: #f33c3c;
    font-size: 0.9rem;
  }
  .blog-text .blog-title{
    font-size: 1.3rem;
    font-weight: 500;
    color: #272727;
  }
  .blog-text p{
    color: #9b9b9b;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px 0px;
  }
  .blog-text a{
    color: #0f0f0f;
  }
  .blog-text a:hover{
    color: #c74242;
    transition: all ease 0.3s;
  }
  
  
  @media(max-width:1250px){
  
    .blog-box{
        width: 300px;
    }
  } 
  @media(max-width:1100px){
    .blog-box{
        width:70%;
    }
  }
  
  @media(max-width:550px){
   
    .blog-box{
        margin: 20px 10px;
        width: 100%;
    }
    #blog{
        padding:20px;
    }
  } 

 */

 .blog-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    margin-bottom: 10vh;
  }


 #blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1; 
    padding: 40px 20px; 
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .blog-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .blog-heading h3 {
    font-size: 2.4rem;
    color: #e59124;
    font-weight: 600;
    text-align: center; 
  }
  
  .blog-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the start to handle different heights */
    margin: 0; /* Remove margin to handle spacing with padding inside boxes */
    flex-wrap: wrap;
    gap: 20px; /* Use gap to handle spacing between boxes */
  }
  
  .blog-box {
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 1px solid #ececec;
    width: calc(33.33% - 40px); /* Set the width for desktop */
    margin-bottom: 20px; /* Space at the bottom of each box */
    overflow: hidden; /* Ensure the content doesn't spill out */
    display: flex;
    flex-direction: column; /* Stack image and text */
  }
  
  .blog-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .blog-text {
    padding: 15px; /* Reduced padding for all devices */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between elements */
    flex-grow: 1; /* Allow the text box to fill the space */
  }
  
  .blog-text span, .blog-text p, .blog-text a {
    margin-bottom: 10px; /* Consistent spacing for all text elements */
  }
  
  .blog-text .blog-title {
    font-size: 1.2rem; /* Slightly smaller font size for all devices */
    color: #272727;
    font-weight: 500;
  }
  
  .blog-text p {
    color: #9b9b9b;
    font-size: 0.8rem; /* Slightly smaller font size for all devices */
  }
  
  .blog-text a {
    color: #0f0f0f;
    text-decoration: none; /* Remove underline from links */
    font-weight: 500; /* Make 'Read More' links bold */
  }
  
  .blog-text a:hover {
    color: #c74242;
    transition: color 0.3s ease;
  }
  
  /* Responsive Styles */
  @media (max-width: 1250px) {
    .blog-box {
      width: calc(50% - 40px); /* Two columns layout */
    }
  }
  
  @media (max-width: 768px) {
    .blog-box {
      width: calc(100% - 40px); /* Full width for tablets and down */
    }
  
    .blog-heading h3 {
      font-size: 2rem; /* Adjust heading size for smaller devices */
    }
  }
  
  @media (max-width: 550px) {
    #blog {
      padding: 20px;
    }
  
    .blog-box {
      width: calc(100% - 20px); /* Full width, reduced padding for small screens */
    }
  
    .blog-text .blog-title {
      font-size: 1rem; /* Reduce title size for small screens */
    }
  
    .blog-text p {
      font-size: 0.7rem; /* Reduce paragraph size for small screens */
    }
  }
  



  
 
  
  /* Responsive Blog Section */
  @media (max-width: 1250px) {
    .blog-box {
      width: calc(50% - 40px); /* Adjust for two-column layout */
    }
  }
  
  @media (max-width: 768px) {
    .blog-heading h3 {
      font-size: 1.8rem; /* Smaller font size for tablet and smaller devices */
    }
    .blog-box {
      width: calc(100% - 40px); /* Full width for tablet and smaller devices */
    }
  }
  
  @media (max-width: 550px) {
    #blog {
      padding: 20px 10px; /* Reduced padding for small screens */
    }
    .blog-box {
      width: calc(100% - 20px); /* Full width for small screens, adjusted padding */
    }
    .blog-heading h3 {
      font-size: 1.5rem; /* Even smaller font size for mobile devices */
    }
    .blog-text .blog-title {
      font-size: 1rem; /* Adjusted font size for readability on mobile */
    }
    .blog-text p {
      font-size: 0.8rem; /* Adjusted paragraph size for mobile */
    }
  }
  
  /* Ensure Navbar and Footer are always visible and not overlapped */
  .navbar {
    /* Your existing navbar styles */
    z-index: 1000; /* Ensure navbar is above other content */
  }
  
  .footer {
    /* Your existing footer styles */
    z-index: 1000; /* Ensure footer is above other content */
  }
  