

/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  /* Header Section */
  #main {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  
  .home {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .name {
    text-align: center;
    font-family: "Poppins", sans-serif;
    max-width: 90%; /* Adjusted for better responsiveness */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .name h2 {
    font-size: 2.7rem; /* Adjust font-size for larger screens */
    color: #ffffff;
    margin: 0;
    letter-spacing: 2px;
  }
  
  .name .details {
    font-size: 1.8rem; /* Adjusted for better readability */
    color: #c5c5c5;
  }
  
  /* Dynamic Texts Animation */
  .wrapper .dynamic-txts span {
    display: block;
    animation: spin_words 6s infinite;
    font-size: 4rem; /* Adjusted for better visibility */
    color: #ffffff;
  }
  
  /* Header Buttons */
  .header-btns {
    justify-content: center;
    align-items: center; 
    flex-direction: row; 
  flex-wrap: wrap; 
    width: 100%;
    margin-top: 0px;
    z-index: 2;
  }
  
  

  .header-btn {
    width: 180px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #e59124;
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none;
    margin: 0 auto; 
  }

 
  
  .header-btn:hover {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #FFFFFF;
  }

  .details span{
    font-family: "Poppins", sans-serif;
    color: #fff;
  }
  
  /* Waves */
  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15vh; /* Adjusted for better responsiveness */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .name h2 {
      font-size: 2rem;
    }
  
    .name .details {
      font-size: 1.4rem;
    }
  
    .wrapper .dynamic-txts span {
      font-size: 3rem;
    }
  
    .header-btns {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .header-btn {
      margin-bottom: 20px;
      justify-content: center; /* Adds space between buttons on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .name h2 {
      font-size: 1.5rem;
    }
  
    .name .details {
      font-size: 1rem;
    }
  
    .wrapper .dynamic-txts span {
      font-size: 2rem;
    }
  }
  

  @media (max-width: 768px) {
    .header-btns {
      flex-direction: column; 
      justify-content: center; 
    }
  }








  /* Existing styles... */

.bouncing-period {
  display: inline-block;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* More of your existing styles... */
