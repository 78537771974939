/* #about {
    
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 5% 0px 5%;
    position: relative;
    background-color:black;
}

.about-text {
    width: 45%;
    color: #fff;
}

.about-text h1 {
    font-size: 3rem;
    color: #ffffff;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
}

.about-text p {
    text-align: justify;
    width: 80%;
    font-size: 1.2rem;
    color: #cecece;
}

.about-text button {
    margin-top: 20px;
    width: 140px;
    height: 45px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #845aff;
}

.about-text button:hover {
    box-shadow: 5px 10px 30px rgba(242, 240, 0, 0.336);
    background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}

.about-image {
    width: 50%;
}

.about-image img {
    width: 100%; 
    max-width: 600px; 
}


@media (max-width: 768px) {
    #about {
        flex-direction: column;
        padding: 20px;
    }

    .about-text, .about-image {
        width: 100%;
    }

    .about-text p {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .about-text h1 {
        font-size: 2rem; 
    }

    .about-text p {
        font-size: 1rem;
    }
}





 */
/* 
#about {
  margin-top: 100px;
  width: 100%;
  height: 30vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 0px 5%;
  position: relative;
}

.about-text {
  width: 45%;
  color: #fff;
  list-style: circle;
}
.about-text h1 {
  font-size: 3rem;
  color: #ffffff;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}
.about-text p {
  text-align: justify;
  width: 80%;
  font-size: 1.2rem;
  color: #fff;
}
.about-text button {
  margin-top: 20px;
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #845aff;
}
.about-text button:hover {
  box-shadow: 5px 10px 30px rgba(242, 240, 0, 0.336);
  background-color: rgb(0, 0, 0);
  transition: all ease 0.3s;
  color: #ffffff;
}
.about-image {
  width: 50%;
}
.about-image img {
  width: 600px;
} */



/* 
.about-container {
  margin-top: 0px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 0px 5%;
  position: relative;
  flex-wrap: wrap;
}

.about-image {
  width: 50%;
  box-sizing: border-box;
  order: 1;
}

.about-image img {
  max-width: 100%;
  height: auto;
  display: block;
}

.about-text {
  width: 50%;
  box-sizing: border-box;
  order: 2;
}

.about-text h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.about-text p {
  text-align: justify;
  width: 100%;
  font-size: 1.2rem;
  color: #fff;
}

@media (max-width: 767px) {
  .about-container {
    flex-direction: column;
  }
  
  .about-image {
    order: 1;
    width: 100%;
  }
  
  .about-text {
    order: 2;
    width: 100%;
  }
} */

/* Apply base styles for the container, header, and buttons */
.marketing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.marketing-header h1 {
  margin-bottom: 0.5em;
}



/* Grid layout for the service boxes */
.marketing-services {
  display: grid;
  gap: 20px;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  color: white;
}

/* Styles for individual boxes */
.marketing-box {
  background: #222;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marketing-icon {
  width: 64px; /* Adjust as necessary */
  margin-bottom: 16px;
}

.marketing-title {
  font-size: 1rem;
  color: white;
}

/* Ensure that the grid adapts to different screen sizes */
@media (max-width: 768px) {
  .marketing-services {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .marketing-services {
    grid-template-columns: 1fr; /* Single column layout for very small screens */
  }
}
