/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap'); */
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap);


/* @tailwind base;
@tailwind components;
@tailwind utilities; */


body{
    margin:0px;
    padding: 0px;
    background-color: #000000;
    font-family: "Poppins", sans-serif;
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
    color:#ffffff;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
/* .logo img{
    width: 90px;
} */
/* #main{
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/bg1.jpg');
    position: relative;
    z-index: 0;
}


#main::before {
    position: absolute;
    content: "";
    background: -moz-radial-gradient(center, ellipse cover, rgba(192, 90, 255, 1) 0%, rgba(67, 39, 158, 1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 85, 219, 1) 0%, rgba(52, 108, 255, 1) 100%);
    background: radial-gradient(ellipse at center, rgba(133, 90, 255, 0.38) 0%, rgba(8, 14, 16, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#86825aff', endColorstr='#99080e10', GradientType=1 );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  } */


/* nav{
  font-family: "Poppins", sans-serif;	
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
	background-color: #000000;
}
nav ul{
	display: flex;
}
.active{
    background-color: #FF1414;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
}
.active:hover{
    background-color: #000000;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
}
nav ul li a{
  font-family: "Poppins", sans-serif;	
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:#ffffff;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
nav ul li a:hover{
	background-color: #e59124;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	transition: all ease 0.2s;
} */
/* nav .menu-btn,
.menu-icon{
		display:none;
	} */

/*   
.menu-icon {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2; 
}

@media (max-width: 768px) {
  .nav ul {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav ul li {
    margin-top: 1rem;
  }

  .nav ul li a {
    font-size: 1.2rem;
  }

  .menu-btn:checked ~ .menu {
    transform: translateX(0);
  }

  .logo img {
    width: 150px;
    height: auto;
  }
} */

/*   
.name{
    text-align: center;
	  font-family: "Poppins", sans-serif;
	width: 900px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.name span{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
}
.name .details{
    font-size: 23px;
    text-align: center;
    color: #c5c5c5;
    font-family: "Poppins", sans-serif;
}
.name h2{
  font-family: "Poppins", sans-serif;	
  font-size: 2.7rem;
  font-weight: 500;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.name h1{
  font-family: "Poppins", sans-serif;	
  font-size: 5rem;
	margin:0px;
	letter-spacing: 2px;
  line-height: 100%;
	color:#ffffff;
}
.header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 40%;
}
.header-btn{
    
	width:180px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #e59124;
  

    border-radius: 5px;
	color:#ffffff;

 
}

/* .header-btn img{
  width: 25px;
} */

/* .header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FFFFFF;
}

.service-header{
  position: relative;
  margin-top: 100px;
} */


/* animated text heading  */
/* .wrapper{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .dynamic-txts{
  text-align: center;
  margin-left: 15px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts span{
  display: block;
  animation: spin_words 6s infinite;
  font-family: "Poppins", sans-serif;	
  font-size: 5rem;
  font-weight: 500;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
@keyframes spin_words {
  10%{
   transform: translateY(-112%);
  }
  25%
  {
    transform: translateY(-100%);
  }
  35%
  {
    transform: translateY(-212%);
  }
  50%
  {
    transform: translateY(-200%);
  }
  60%
  {
    transform: translateY(-312%);
  }
  75%
  {
    transform: translateY(-300%);
  }
  85%
  {
    transform: translateY(-412%);
  }
  100%
  {
    transform: translateY(-400%);
  }
} */ 



#features{
    width:100%;
    height:120vh;
    box-sizing: border-box;
    font-family:'Lato';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #000000;
}
#features h1{
    color: #fff;
    font-size: 3rem;
}

#services{
  padding-top: 50px;
}

.a-container{
    display: flex;
    justify-content: center;
    align-content: center;
 
}
.a-box{
    background-color: #000000;
    width:250px;
    height: 300px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
}
.a-b-img{
    width: 100%;
    height: 60%;

}
.a-b-img img{
    padding: 15px;
    margin-top: 40px;
    width:100%;
    height: 50%;

}
.a-box:hover{
    border: 1px solid #FFFFFF;
    box-shadow: 2px 2px 12px rgba(184, 0, 0, 0.445);
}
.a-b-text{
    width: 100%;
    height: 0%;
    background-color:#000000;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text h2{
    color: #fff;
}
.a-b-text p{
    margin: 0px;
    color:#ffffffa6;
    font-size: 1.1rem;
    font-family:'Lato';
    display: block;
    display: -webkit-box;
    max-width: 80%;
    margin-top: 5px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.a-b-text p:hover{
    color:#ffffff;
}
.a-b-text a{
    margin-top:15px ;
}
#presentaion{
    padding-top: 30px;
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.pr-heading{
    text-align: center;
	font-family:'Lato';
	width: 600px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.pr-heading span{
    color: #FF1414;
    /* color: #c5c5c5; */

}
.pr-heading .details{
    font-size: 23px;
    color: #c5c5c5;
}
.pr-heading .details{
    font-size: 1.2rem;
}
.pr-heading h1{
	font-family:'Lato';
	font-size: 4rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.pr-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 35%;
}
.pr-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.pr-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FF1414;
}

/* #about{
    margin-top:100px ;
	width:100%;
	height: 30vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px 5% 0px 5%;
	position: relative;
}

.about-text{
    width: 45%;
    color: #fff;
    list-style: circle;
}
.about-text h1{
	font-size: 3rem;
	color:#ffffff;
    font-weight: 500;
    margin: 0px;
	padding: 0px;
}
.about-text p{
  text-align: justify;  
  width: 80%;
	font-size: 1.2rem;
	color:#cecece;
	
}
.about-text button{
    margin-top: 20px;
	width: 140px;
	height: 45px;
	border-radius: 10px;
	border: none;
    outline: none;
    color: #ffffff;
	background-color:#845aff;
}
.about-text button:hover{
    box-shadow: 5px 10px 30px rgba(242, 240, 0, 0.336);
	background-color: rgb(0, 0, 0);
    transition: all ease 0.3s;
    color: #ffffff;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 600px;
} */

.error-message
{
  color: #FF1414;
}

.map-container {
  margin-top: 20px;
  margin-bottom: 20vh;
  width: 80%;
  border-radius: 15px;
  overflow: hidden;
}

.headingtxt{
  color: white;
  font-size: 3rem;
}

#contact{
    width:100%;
    margin-top: 5vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact h1{
    color: #fff;
    font-size: 3rem;
}



#contact form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #ffffff2d;
    color: #ffffff;
    border-radius: 5px;
}

#contact form select{
  width:100%;
  height:50px;
  margin:5px 0px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #ffffff2d;
  color: #000000;
  border-radius: 5px;
}


#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    background: #e59124;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
}
@media(max-width:620px){
    #contact form{
        width:90%;
    }
}

@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.name{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .name h2{
        font-size: 1.5rem;
    }
    .name h1{
        font-size: 3rem;
    }
    .name .details{
        font-size: 1rem;
    }
    .pr-heading{
		left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .pr-heading h2{
        font-size: 1.5rem;
    }
    .pr-heading h1{
        font-size: 3rem;
    }
    .pr-heading .details{
        font-size: 1rem;
    }
	.about-image img{
		height:400px;
    }
    #features{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    .a-b-img img{
		object-fit:contain;
    }
    
    

}

@media(max-width:970px){
	#main{
		height: 650px;
	}
	.name{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
    }
    .pr-heading{
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .header-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
    .pr-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
	#about{
		justify-content: center;
        padding-top: 0px;
        text-align: center;
        
        margin-top: 0px;
	}
	.about-text{
        width: 90%;
	}
	.about-text h1{
		font-size:4rem;
    }
    .about-text p{
        width: 100%;
    }
    #features{
        margin-bottom: 100px;
    }
}
@media(max-width:600px){
	.name{
		width:60%;
    }
    .pr-heading{
		width:60%;
    }
}

@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .btns{
            margin: 0;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#000000;
            border-bottom:4px solid #FF1414;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
            /* border:1px solid red; */
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:400px;
            }
        }

/* for service page style  */

.container {
    position: relative;
    margin-top: -100px;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: left;
    width: 100%;
    overflow: hidden; /* Hides the text initially */
  }
  
  .animated-text {
    font-size: 5em;
    color: white;
    white-space: nowrap; /* Prevents text from wrapping */
    animation: slideInLeft 1.5s ease-out, fadeIn 1s 1.5s ease-out; /* You can adjust the animation duration and delays */
  }
  
  @keyframes slideInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

/* text and image box css */

/* .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #000;
    animation: fadeInBox 1s ease-out; 
  }
  
  .side-image, .side-text {
    flex: 1;
    margin: 0 20px;
  }
  
  .side-image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    animation: fadeInImage 5s ease forwards;
  }
  
  .side-text {
    animation: slideInText 5s ease forwards;
    color: #fff;
  }
  
  @keyframes fadeInBox {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes fadeInImage {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideInText {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
   */



   /* html {
    scroll-behavior: smooth;
  }
  
  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #000;
    color: #fff;
    opacity: 0; 
    transform: scale(0.9);
    transition: opacity 0.5s, transform 0.5s; 
  }
  
  .side-image, .side-text {
    flex: 1;
    margin: 0 20px;
  }
  
  .side-image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .content-box.in-view {
    opacity: 1; 
    transform: scale(1); 
  }
  
 
  .content-box.in-view .side-image img {
    animation: fadeInImage 5s ease forwards;
  }
  
  .content-box.in-view .side-text {
    animation: slideInText 5s ease forwards;
  }
  
  @keyframes fadeInImage {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes slideInText {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
   */


   html {
    scroll-behavior: smooth;
  }
  
  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #000;
    color: #fff;
    opacity: 0; /* Start with content boxes hidden */
    transform: scale(0.9); /* Initial scale */
    transition: opacity 0.5s, transform 0.5s; /* Smooth transition for fade-in and scaling */
  }
  
  .side-image, .side-text {
    flex: 1;
    margin: 0 20px;
  }
  
  .side-image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  /* When the content box is in view */
  .content-box.in-view {
    opacity: 1; /* Make content box visible */
    transform: scale(1); /* Scale to normal size */
  }
  
  /* Optional: Add animations for image and text */
  .content-box.in-view .side-image img {
    animation: fadeInImage 5s ease forwards;
  }
  
  .content-box.in-view .side-text {
    animation: slideInText 5s ease forwards;
  }
  
  @keyframes fadeInImage {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes slideInText {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }


  /* Mobile Styles */
@media only screen and (max-width: 768px) {
  .content-box {
    flex-direction: column; /* Stack the image and text on top of each other on small screens */
    text-align: center; /* Center-align the text for better readability */
  }

  .side-image, .side-text {
    margin: 20px; /* Add more margin for smaller screens */
  }

  .side-text h1, .side-text p, .icon-paragraph-container {
    font-size: smaller; /* Adjust font size to fit on small screens */
  }

  .icon-paragraph-container .sicons {
    /* Adjust icon sizes if necessary */
    font-size: 24px; /* Example size, adjust as needed */
  }

  .icon-paragraph-container {
    flex-direction: row; /* Optional: Change to row direction for icons to be next to text */
    align-items: center; /* Align icon and text in the middle */
    justify-content: center; /* Center the items horizontally */
  }
  
  /* If your icons are from a font icon set, adjust the font-size as well */
  .sicons.left-icon {
    font-size: 1.5em; /* Adjust the size as per your design */
  }

  /* Scale down the images to fit smaller screens */
  .side-image img {
    width: 80%; /* Adjust this value as needed */
    height: auto; /* Maintain aspect ratio */
  }

  /* Adjust padding and margins for better spacing on mobile */
  .content-box {
    padding: 10px;
  }
}

/* Consider even smaller devices */
@media only screen and (max-width: 480px) {
  .side-text h1, .side-text p, .icon-paragraph-container {
    font-size: even-smaller; /* Adjust font size for very small screens */
  }

  /* Scale down the images even more to fit very small screens */
  .side-image img {
    width: 90%; /* Adjust this value as needed */
  }
}



/* contact us page css */

/* .contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}

.contact .content h2 {
  font-size: 36px;
  font-weight: 500;
  color: white;
}

.contact .content p {
  font-size: 15px;
  font-weight: 300;
  color:white;
  
}

.mycontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-right: 300px;
}

.mycontainer .contactInfo .box {
  position: relative;
  padding: 20px 0;
  display: flex;
  

}

.mycontainer .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 25px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
}

.mycontainer .contactInfo .box .text h3 {
  font-weight: 500;
  color: #e59124;
}

.contactFrom {
  width: 40%;
  padding: 40px;
  background: #fff;
  margin-left: 200px; 
}

.contactFrom h2 {
  font-size: 30px;
  color: #e59124;
  font-weight: 500;
}

.contactFrom .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contactFrom .inputBox input,
.contactFrom .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contactFrom .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contactFrom .inputBox input:focus ~ span,
.contactFrom .inputBox input:valid ~ span,
.contactFrom .inputBox textarea:focus ~ span,
.contactFrom .inputBox textarea:valid ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contactFrom .inputBox input[type="Submit"] {
  width: 100px;
  background: #e59124;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}

@media (max-width: 991px) {
  .contact {
      padding: 50px;
  }
  .mycontainer {
      flex-direction: column;
  }
  .mycontainer .contactInfo {
      margin-bottom: 40px;
  }
  .mycontainer .contactInfo,
  .contactFrom {
      width: 100%;
      margin-left: 0; 
  }
} */


/* code for nav service  */



.nav.active {
  background-color: #000; /* Adjust background color as needed when scrolled */
}

.menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}

.menu li {
  position: relative;
}

.menu li:hover .dropdown-content {
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  font-family: "Poppins", sans-serif;

  top: 100%;
  left: 0;
  background-color:  black; 
  border-radius: 4px;
  padding: 10px;
  width: 300px;
}

.dropdown-content a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;

  color: white; /* Adjust text color as needed */
  transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
  background-color: white; /* Adjust background color on hover as needed */
  color: #000000;
}



/* for service page css  */

/* YourComponent.css */
.content-container {
  margin-top: 96.4px;
  justify-content: center;
  align-items: center;

}


.servicecontainer {
  height: 300px;
  overflow: hidden;
  position: relative;
}

.animation-title {
  position: absolute;
  animation: fade 4s ease-in-out infinite;
  white-space: nowrap;
  color: #E1D9D1;
  font-size: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}


.home {
  min-height: 100vh;
  width: 100vw;
  background-image: url(images/img1.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  padding: 0 1rem;
  position: relative;
  overflow: hidden !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.home h1,
.home h2 {
  color: #fff;
  animation: fadeIn 1s ease-out; /* You can adjust the duration and timing function as needed */
  font-size: 1.5rem; /* Adjust as needed */
}

.home h2 {
  font-size: 3rem; /* Adjust as needed */
}

.home .wave {
  position: absolute;
  bottom: -.5rem;
  left: 0;
  height: 11rem;
  width: 100%;
  background: url(images/blackwave.png);
  background-size: 100rem 11rem;
  animation: waves 8s linear infinite;
  background-repeat: repeat-x;
}

.home .wave2 {
  animation-direction: reverse;
  animation-duration: 6s;
  opacity: .3;
}

.home .wave3 {
  animation-duration: 4s;
  opacity: .5;
}

@keyframes waves {
  0% {
      background-position-x: 0;
  }
  100% {
      background-position-x: 100rem;
  }
}



/* FAQ Style  */

.faqheader {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('images/faqheader.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 5%;
  border-radius: 30px;
  max-height: 380px;
  color: white;
}
.faqheader-title {
  font-size: 48px;
  letter-spacing: 1.5;
}
.faqheader-desc {
  font-size: 14px;


  letter-spacing: 1;
  text-align: center;
}
/* .search {
  width: 60%;
  height: 50px;
  background-color: var(--whiteColor);
  margin-top: 5%;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.search input {
  width: 80%;
  height: 100%;
  padding: 1% 3%;
  background: transparent;
  border: none;
}
.search button {
  width: 20%;
  min-width: 100px;
  height: 100%;
  background-color:#845aff ;
  color: var(--whiteColor);
  border: none;
  border-radius: 30px;
  cursor: pointer;
}
.search button:hover {
  background-color: var(--blackColor);
} */
.faq
{
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;
padding: 3%;

}
.faq-name{
    flex: 0.5;
    padding: 2% 2% 0 0;

}
.faq-header{
   padding: 5% 0 0 0;
   font-size: 40px;
   letter-spacing: 2;

}
.faq-img{
    width: 100%;
    max-width: 400px;
}
.faq-box{
    flex: 1;
    min-width: 320px;
    padding: 2% 0 4% 4%;
    border-left: 2px solid white;

}
.faq-wrapper{
    width: 100%;
    padding: 1.5rem;
    border-bottom: 1px solid white;


}
.faq-title{
    display: block;

    position:relative;
    width: 100%;
    letter-spacing: 1.2;
    font-size: 24px;
    font-weight: 600;
    color:white;
}
/* creating arrow */
.faq-title::after{
 width: 10px;
 height: 10px;
 content: "";
 float: right;
 border-style:solid ;
 border-width: 2px 2px 0 0;
 transform: rotate(135deg);
 transition: 0.4s ease-in-out;

}
.faq-details{
    line-height: 1.5;
    letter-spacing: 1;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    font-size: 18px;
    color: #e59124;
}
.faq-trigger{
    display: none;
}
.faq-trigger:checked + .faq-title + .faq-details{
    max-height: 300px;
}
.faq-trigger:checked + .faq-title::after{
    transform: rotate(-45deg);
    transition: 0.4s ease-in-out;
}
/* moblie view */
@media screen   and(max-width:680px){
.search {
    width:100%;
}
.faq-title{
    font-size: 20px;
}
}


/* about page  */

.section{
  width: 100%;

}
.section .container{
  width: 80%;
  display: block;
  margin: 0px auto;
  padding: 50px 0px;
}

.container .title{
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

}
.container .title h1{
  text-transform: uppercase;
  font-size: 35px;
  color: #fff;

}
.container .title h1::after{
content: "";
height:  5px;
width: 100px;
background-color: #fff;
border-radius: 25px;
display: block;
margin: auto;


}
.content{
float: left;
width: 55%;

}
.image-setion{
  float: right;
  width: 40%;

}
.image-setion img{
  width: 100%;
  height: auto;
}
.content .article h3{
 color:#fff;
 text-align: justify;
 font-size: 17px;
}

.content .article h2{
  color:#fff;
  text-align: justify;
  font-size: 17px;
 }

.content .article p{
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.container .social{
          width: 100%;
         clear: both;
         margin-top: 50px;
         text-align: center;
         display: inline-block;

      }
@media screen and (max-width:768px) {
  .container
{
  width: 80%;
  display: block;
  margin: auto;
}
}       

.copyright{
  color: #fff;
}



/* website service page  */


/* section {
  height: 20vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.service-pages{
  margin-bottom: 500px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}
.card {
  width: 100%;
  height: 50vh;
  text-align: center;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, #e59124 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}



.card h4{
  color: black;
}

.icon-wrapper {
  background-color: #e59124;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}
.card:hover {
  background-position: 0 100%;
}
.card:hover .icon-wrapper {
  background-color: #ffffff;
  color: #e59124;
}
.card:hover h3 {
  color: #ffffff;
}
.card:hover h4 {
  color: #ffffff;
}
.card:hover p {
  color: #f0f0f0;
}
@media screen and (min-width: 768px) {
  section {
    padding: 0 2em;
  }
  .column {
    flex: 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  section {
    padding: 1em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.serviceicon{
  width: auto;
  height: auto;
}


.service-text{
  text-align: center;
  color: #fff;
}

.service-section {
  margin-top: 10vh;
  margin-bottom: 300vh; 
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  margin-bottom: 10vh;
} */



/* blog css */

/* #blog{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 100vh;
  margin-bottom: 100vh;
}
.blog-heading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.blog-heading span{
  color: #f33c3c;
}
.blog-heading h3{
  font-size: 2.4rem;
  color: #e59124;
  font-weight: 600;
}
.blog-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  flex-wrap: wrap;
}

.blog-box{
border-radius: 5px;
  width: calc(33.33% - 40px);
  background-color: #FFFFFF;
  border: 1px solid #ececec;
  margin: 20px 20px;

}
.blog-img{
  width:100%;
  height: auto;
}
.blog-img img{
  width:100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.blog-text{
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.blog-text span{
  color: #f33c3c;
  font-size: 0.9rem;
}
.blog-text .blog-title{
  font-size: 1.3rem;
  font-weight: 500;
  color: #272727;
}
.blog-text p{
  color: #9b9b9b;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 20px 0px;
}
.blog-text a{
  color: #0f0f0f;
}
.blog-text a:hover{
  color: #c74242;
  transition: all ease 0.3s;
}


@media(max-width:1250px){

  .blog-box{
      width: 300px;
  }
} 
@media(max-width:1100px){
  .blog-box{
      width:70%;
  }
}

@media(max-width:550px){
 
  .blog-box{
      margin: 20px 10px;
      width: 100%;
  }
  #blog{
      padding:20px;
  }
} */



/* blodone css */
/* 

.blog-post {
  margin: auto;
  max-width: 800px;
  text-align: center;
  padding-top: 100px; 
}

img {
  width: 100%; 
  height: 80%;
  border-radius: 10px;
  margin-top: 20px;
}

p {
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 10vh;
}

h1 {
  color: #e59124; 
}

.question {
  margin-top: 30px;
 color:white;
}


@media (max-width: 768px) {
  .blog-post {
    padding-top: 80px; 
  }
}


@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
  }
}
 */






 /* digital marketing icons-pragraph  */

 /* Optional: If you want to make the icon larger without changing the padding */
.sicons > svg {
  width: 40px; /* Adjust the width of the icon */
  height: 40px; /* Adjust the height of the icon */
}

.icon-paragraph-container {
  display: flex;
  align-items: center;

  margin-bottom: -70px;
}

.left-icon
 {
  color: white;
  background-color: #e59124;
  border-radius: 50%;
  padding: 10px; /* Adjust the padding to change the size of the icon */
  margin-top:10px; 
  margin-bottom: 100px;
}

/* .icon-paragraph-container2 {
  display: flex;
  align-items: center;
 
} */




.p-text {
  text-align: justify;

}